<template>
  <div class="payment-error">
    <base-icon
      name="warning"
      size="xlarge"
    />
    <div class="payment-error__text-block">
      <p class="payment-error__title">
        {{ $t('checkout.title-error') }}
      </p>
      <p
        class="payment-error__text"
        v-html="$t('checkout.text-error')"
      />
    </div>
  </div>
</template>

<script>
import { WebSocketClient } from '@/services/websocket-client';

export default {
    components: {
        BaseIcon: () => import(/* webpackChunkName: "BaseIcon" */ '@RepoComponent/BaseIcon/BaseIcon'),
    },
    async mounted() {
        this.$store.dispatch('websocket_store/axn_websocketActionID', 'purchaseCheckoutError');

        try {
            await WebSocketClient.connect({
                server: 'Kestrel',
                url: 'vmsd',
                actionType: 'purchaseCheckoutResponseError',
            });
        } catch {
            console.log('Failed to open websocket connection');
        }
    },
};
</script>

<style src="./PaymentErrorView.scss" lang="scss">
</style>
